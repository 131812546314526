import React, { useContext, useState, useEffect  } from 'react';
import { Row, Col, Divider, Button,Image, Modal, Input, Select, message, Space,  } from 'antd';
import splash from '../assets/splashImage.png';
import sponsors from '../assets/sponsorsGrey.png';

import { UserOutlined } from '@ant-design/icons';
import { UserContext } from "../context/UserContext";

import ParticlesBg from 'particles-bg'
import Lottie from 'react-lottie';

import * as web3 from '../assets/web3.json'
import * as walletCreated from '../assets/walletCreated.json'
import * as success from '../assets/success.json'
import * as sparkleAnimation from '../assets/sparkleAnimation.json'
import { Checkbox } from 'antd';


/**
 * Application home page component
 * @returns {JSX}
 */
const HomePage = () => {
    const [user, setUser] = useContext(UserContext);
    const [loginVisible, setLoginVisible] = useState(false);
    const [signUpVisible, setSignUpVisible] = useState(false);
    const [createWalletVisible, setCreateWalletVisible] = useState(false)
    const [createWalletPage, setCreateWalletPage] = useState(0)
    const [walletProvider, setWalletProvider] = useState("sol");
    const [seedPhrase, setSeedPhrase] = useState("")
    const [newWalletName, setNewWalletName] = useState("")    
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState("")
    const [errorMessage, setErrorMessage] = useState("");
    const [walletVisible, setWalletVisible] = useState(false);
    const [solWalletList, setSolWalletList] = useState([]);
    const [btcWalletList, setBtcWalletList] =useState([]);
    const { Option, OptGroup } = Select;
    const [selectedWallet,setSelectedWallet]=useState("");
    const[tokenAmount, setTokenAmount] = useState(0);
    const[usdAmount, setUSDAmount] = useState(0);
    const[activeWallet, setActiveWallet] = useState(["SOL"]);
    const [publicAddress, setPublicAddress] = useState("");
    const [tokenImage, setTokenImage] = useState("https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png");
    const [tokenType, setTokenType] = useState("Solana");
    const [marketPrice,setMarketPrice] = useState(0);
    const [marketPercent,setMarketPercent] = useState(0);
    const [walletExists, setWalletExists] = useState(false)
    const [sendTransaction, setSendTransaction] = useState(false)
    const [sendTransferAddress, setSendTransferAddress] = useState("")
    const [sendTransferAmount, setSendTransferAmount] = useState(0)
    const { TextArea } = Input;
    const size = useWindowSize();
    const hour= 1000 * 60 * 60;


    useEffect(() => {
        if(localStorage.getItem("username") && ((Number(localStorage.getItem("date")) - (Date.now())) > -1800000)){
            
            setUsername(localStorage.getItem("username"))
            setLoggedIn(true)
            loadBtcWallets()
            loadSolWallets()

        }


    }, [username]);

    function onChange(e) {
        //.log(`checked = ${e.target.checked}`);
    }
    
    const showLogin = () => {
        setSignUpVisible(false);
        setLoginVisible(true);
    };

    const cancelLogin = () => {
        setLoginVisible(false);
        setErrorMessage("")
    };

    const showSignUp = () => {
        setLoginVisible(false);
        setSignUpVisible(true);        
    };

    const cancelSignUp = () => {
        setSignUpVisible(false); 
        setErrorMessage("")
    };

    const showWallet = () => {
        setCreateWalletVisible(false)
        loadBtcWallets()
        loadSolWallets()
        setWalletVisible(true);
        setCreateWalletPage(0)

    };

    const cancelWallet = () => {
        setWalletVisible(false); 
    };

    const cancelCreateWallet = () => {
        setCreateWalletVisible(false);
        setCreateWalletPage(0) 
        setErrorMessage("")
    };

    const publicAddressCopy = () => {
        navigator.clipboard.writeText(publicAddress)
        message.success('Address has been copied!');
    };

    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
          width: undefined,
          height: undefined,
        });
        useEffect(() => {
          // Handler to call on window resize
          function handleResize() {
            // Set window width/height to state
            setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            });
          }
          // Add event listener
          window.addEventListener("resize", handleResize);
          // Call handler right away so state gets updated with initial window size
          handleResize();
          // Remove event listener on cleanup
          return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
      }

    const loadSolWallets = async () => {
        const options = {
            method: 'POST',                 
        };
         
        await fetch(`https://api.alphalibertee.com/sol/getAllWallet?username=${username}`, options)
        .then(response => response.json())
            .then(data => {
           // console.log(data)
            if(data["wallets"].length>0){      
                walletLoad([0,data["wallets"][0][0]])         
                setSolWalletList(data["wallets"])
                setWalletExists(true)   
                      
            }

            //console.log(solWalletList)     
        });
    }

    function handleChange(value) {
        if(value=="Solana")
            setWalletProvider("sol")
        else if(value=="btc")
            setWalletProvider("btc")
        console.log(value)

    }

    const loadBtcWallets =async () => {
        const options = {
            method: 'POST',                 
        };
         
        await fetch(`https://api.alphalibertee.com/btc/getAllWallet?username=${username}`, options)
        .then(response => response.json())
            .then(data => {
          //  console.log(data)
            if(data["wallets"].length>0){    
                walletLoad([0,data["wallets"][0][0]])           
                setBtcWalletList(data["wallets"])
                setWalletExists(true)          
            }              
                
        });
    }

    const walletLoad =async (walletInfo) => {
        
        
        const options = {
            method: 'POST',                 
        };
        
        if(walletInfo[0]==0){
           // console.log("sol")
            await fetch(`https://api.alphalibertee.com/sol/solBalance?username=${username}&wallet_name=${walletInfo[1]}&clientType=0`, options)
            .then(response => response.json())
                .then(data => {
                getSolanaPrice()
                //console.log(data["balance"]["SOL"])
                setTokenAmount(data["balance"]["SOL"]["amount"].toFixed(2))  
                setActiveWallet(walletInfo[1])  
                setUSDAmount(data["balance"]["SOL"]["converted"].toFixed(2))
                setPublicAddress(data["balance"]["SOL"]["walletAddress"])
                setTokenImage("https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png")   
                setTokenType("Solana")   
            });
        }
        else if(walletInfo[0]==2){
            createWalletModal()
        }
        else{            
           // console.log("BTC")
            await fetch(`https://api.alphalibertee.com/btc/balance?username=${username}&wallet_name=${walletInfo[1]}`, options)
            .then(response => response.json())
                .then(data => {
                    //console.log(data["BTC"])
                    getBTCPrice()
                    setTokenType("Bitcoin")
                    setTokenAmount(data["BTC"]["amount"])    
                    setActiveWallet(walletInfo[1])
                    setUSDAmount(data["BTC"]["converted"])
                    setPublicAddress(data["BTC"]["walletAddress"])
                    setTokenImage("https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=018")
                      
            });
        }
        
    }

    const createWalletModal = () => {
        cancelWallet()
        setCreateWalletVisible(true)
        setCreateWalletPage(4)        
    }

    const ImportWalletModal = () => {
        cancelWallet()
        setCreateWalletVisible(true)  
        setCreateWalletPage(1)      
    }

    const getSolanaPrice = async () => {
        const options = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          
        };
    
        await fetch(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=solana`, options)
            .then(response => response.json())
            .then(data => {
                setMarketPrice(data[0]["current_price"])
              setMarketPercent(data[0]["price_change_percentage_24h"].toFixed(2));
             
            });
      };
    
      const getBTCPrice = async () => {
        const options = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          
        };
    
        await fetch(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin`, options)
            .then(response => response.json())
            .then(data => {
              setMarketPrice(data[0]["current_price"])
              setMarketPercent(data[0]["price_change_percentage_24h"].toFixed(2));
             
            });
      };

    const login = async () => {
        const signInRequestOptions = {
            method: 'POST',
        };
      
        await fetch(`https://api.alphalibertee.com/login?username=${username}&password=${password}`, signInRequestOptions)
            .then(response => response.json())
            .then(data => {
           // console.log(data)
            if(data["response"]=="Incorrect username/password"){
                setErrorMessage("Incorrect username/password")
            }
            if(data["response"]=="Email not confirmed"){
                setErrorMessage("Email not confirmed.")
            }
            else if(data["success"] == true){
                setUsername(data["response"]["Username"])
                localStorage.setItem("username", data["response"]["Username"])      
                localStorage.setItem("date", Date.now().toString())
               
                setLoggedIn(true)
                setErrorMessage("")
                loadBtcWallets()
                loadSolWallets()
                cancelLogin()  
                
                setTimeout(() => {
                    if(walletExists)
                        window.location.href = "https://alphalibertee.com/wallet";
                }, 3000); 
            }          
        });
    };

    const signUp = async () => {
        const signUpRequestOptions = {
            method: 'POST',
        };
      
          await fetch(`https://api.alphalibertee.com/signUp?username=${username}&email=${email}&password=${password}`, signUpRequestOptions)
            .then(response => response.json())
            .then(data => {
            if(data["response"]=="The password/username does not meet the requirements"){
                setErrorMessage("Your password must contain numbers, special characters, uppercase and lowercase letters.")
            }
            else if(data["response"]=="Username already exists"){
                setErrorMessage("Username already exists.")
            }
            else if(data["response"]=="This email already exists."){
                setErrorMessage("Email already exists.")
            }
            else if(data["success"]==true){
                setLoggedIn(true)
                setErrorMessage("")
                cancelSignUp() 
                showWallet()                  
            }
           // console.log(data)
      
        });
    };

    const createWallet = async () => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            
        };
        
        
         
          
          await fetch(`https://api.alphalibertee.com/${walletProvider}/createWallet?username=${username}&wallet_name=${newWalletName}&platform=`, options)
              .then(response => response.json())
              .then(data => {
                if(data["success"]){
                  setErrorMessage("")
                  loadBtcWallets()
                  loadSolWallets()                  
                  setSeedPhrase("")
                  setNewWalletName("")
                  setCreateWalletPage(6)
                  setTimeout(() => {
                    window.location.href = "https://alphalibertee.com/wallet";
                  }, 3000); 

                }
                else{
                  setErrorMessage(data["Error"])
                }
               
                
              });
    };

    const importWallet = async () => {
        const options = {
            method: 'POST',
        };

        let pathNumber = 0;

        if(walletProvider=="phantom" || walletProvider=="sollet"){
            pathNumber = 0;
        }

        if(walletProvider=="solflare"){
         pathNumber = 1;
        }

        await fetch(`https://api.alphalibertee.com/sol/createWallet?username=${username}&wallet_name=${newWalletName}&privateKey=${seedPhrase}&platform=${pathNumber}`, options)
            .then(response => response.json())
            .then(data => {
            
            if(data["success"]){
                setErrorMessage("")
                setCreateWalletPage(3)
                loadBtcWallets()
                loadSolWallets()                
                setSeedPhrase("")
                setNewWalletName("")
                setTimeout(() => {
                    window.location.href = "https://alphalibertee.com/wallet";
                }, 3000); 
            }
            else{
                setErrorMessage(data["error"])                
            }
            
            
            });
    };

    const walletList = () => {
        return(
            <Select defaultActiveFirstOption={true} size={"large"} style={{ width: 300 }} value={activeWallet} onSelect={(value => walletLoad(value))}>
      
                {
                   solWalletList!=[]?
                        solWalletList.map((wallet,index)=>(
                            <Option  key={"solWallet"+index} title={wallet[0]} value={[0,wallet[0]]} >
                                <p style={{color:"black", fontWeight:600}}>SOL - {wallet[0]}</p>
                                <p>{wallet[1].substring(0, 4)}...{wallet[1].slice(-4)}</p>
                            </Option>
                        ))
                    :<Row></Row>
                }

         

                {
                    btcWalletList!=[]?
                    btcWalletList.map((wallet,index)=>(
                        <Option key={"btcWallet"+index} title={wallet[0]} value={[1,wallet[0]]}>
                            <p style={{color:"black", fontWeight:600}}>BTC - {wallet[0]}</p>
                            <p>{wallet[1].substring(0, 4)}...{wallet[1].slice(-4)}</p>
                        </Option>
                    ))
                    :<Row></Row>                 
                }

                <Option value={[2,"createWallet"]}>+ Create/Connect New Wallet</Option>
            </Select>
        )
        
            
    }



    return (
        <>
            <Modal                
                visible={loginVisible}
                onCancel={cancelLogin}
                footer={null}  
                 
                closable={true}
                style={{background:"transparent", backgroundColor:"transparent", top:0}}
                bodyStyle={{ background:"transparent", backgroundColor:"transparent", paddingLeft:0, paddingRight:0}}
                maskStyle={{background:'black', opacity:.9}}     
                className='transparent'      
                        
            >
                <div style={{backgroundColor:"transparent", paddingTop:25, paddingBottom:25}}>
                    <p style={{marginLeft:10, marginRight:10, fontSize:34, fontWeight:800,  marginBottom:0, color:"white"}}>Welcome to Alpha Libertee!</p>
                    
                    <p style={{marginLeft:10, marginRight:10, marginTop:10, fontSize:15, borderBottom:2, paddingBottom:15, borderColor:"#4a4a4a", color:'white', borderStyle:"solid", borderTop:0, borderLeft:0, borderRight:0}}>Log in to access your wallet</p>
                    
                    
                    <div style={{marginLeft:10, marginRight:10, marginTop:20,  }}>
                        <p style={{color:'red', textAlign:"center"}}>{errorMessage}</p>
                        <Input  size="large" placeholder="Username" prefix={<UserOutlined />} value={username} onChange={username => setUsername(username.target.value)}/>                    
                        <Input.Password size="large" style={{marginTop:20}} placeholder="Password" value={password} onChange={password => setPassword(password.target.value)}/>
                        
                        <p style={{ borderColor:"#4a4a4a", borderStyle:"solid", borderWidth:2.5, borderTop:0, borderLeft:0, borderRight:0, marginTop:25}}></p>

                        <Row justify='center'>
                         <Button type="primary"  block size="large" style={{background:"#0052CC", borderColor:"#0052CC",  fontSize:16, paddingLeft:50, paddingRight:50, marginTop:10 }} onClick={login}>Log In</Button>
                        </Row>
                        {/*}
                        <Row justify='center' onClick={showSignUp}>
                            <a style={{fontWeight:500, color:"black", marginTop:15}} onClick={showSignUp}>or Sign Up</a>
                        </Row>*/}
                    </div>
                </div>
            </Modal>

            <Modal                
                visible={signUpVisible}
                onCancel={cancelSignUp}
                footer={null}
                
                closable={true}
                style={{background:"transparent", backgroundColor:"transparent", top:0}}
                bodyStyle={{ background:"transparent", backgroundColor:"transparent", paddingLeft:0, paddingRight:0}}
                maskStyle={{background:'black', opacity:.9}}     
                className='transparent'        
            >
                <div style={{backgroundColor:"transparent", paddingTop:25, paddingBottom:25}}>

                    <Lottie options={{
                            loop:false,
                            autoPlay:true,
                            animationData:sparkleAnimation,
                            
                        }}
                        height={250}
                        
                        />
                    <p style={{marginLeft:10, marginRight:10, fontSize:34, fontWeight:800,  marginBottom:0, marginTop:-250, color:"white"}}>Sign Up for Free</p>
                    
                    <p style={{marginLeft:10, marginRight:10, marginTop:10, fontSize:15, borderBottom:2, paddingBottom:15, borderColor:"white", color:'white', borderStyle:"solid", borderTop:0, borderLeft:0, borderRight:0}}>Airdropping  $50,000  in  prizes.</p>
                    
                
                    <div style={{marginLeft:10, marginRight:10, marginTop:20,  }}>
                        <p style={{color:'red', textAlign:"center"}}>{errorMessage}</p>
                        <Input  size="large" placeholder="Username" prefix={<UserOutlined />} value={username} onChange={username => setUsername(username.target.value)} style={{background:"transparent", backgroundColor:"transparent", color:"white"}}/>

                        <Input  size="large" style={{marginTop:20, background:"transparent", backgroundColor:"transparent", color:"white"}} placeholder="Your Email Address" value={email} onChange={email => setEmail(email.target.value)} />

                        

                        <Input.Password size="large" style={{marginTop:20}} placeholder="Create Password" value={password} onChange={password => setPassword(password.target.value)}/>

                        <p style={{ borderColor:"white",  borderStyle:"dotted", borderTop:0, borderLeft:0, borderRight:0, marginTop:20, marginBottom:10}}></p>

                        <Checkbox style={{ color:"white", backgroundColor:'#272727', width:"100%", paddingTop:10, paddingBottom:10, paddingLeft:15, paddingRight:15,}} onChange={onChange}>I agree to the <a>terms of use</a> and <a>privacy policy</a></Checkbox>

                            <Row justify='center'>
                                <Button type="primary"  block size="large" style={{background:"#0052CC", borderColor:"#0052CC", marginTop:25, fontSize:16, paddingLeft:50, paddingRight:50,  }} onClick={signUp}>SIGN UP</Button>                            
                            </Row>
                            {/*<Row justify='center' onClick={showLogin}>
                                <a style={{fontWeight:500, color:"white", marginTop:15}}>or Login</a>
                            </Row>*/}
                            
                        
                    </div>

                

                </div>
            </Modal>

            <Modal                
                visible={walletVisible}
                onCancel={cancelWallet}
                footer={null}
                
                closable={true}
                style={{background:"transparent", backgroundColor:"transparent", top:0}}
                bodyStyle={{ background:"transparent", backgroundColor:"transparent", paddingLeft:0, paddingRight:0, marginLeft:10, marginRight:10,}}
                maskStyle={{background:'black', opacity:.9}}     
                className='transparent'         
                               
            >               
                

                
                <div style={{backgroundColor:"transparent", paddingTop:25, paddingBottom:45}}>
                    
                    <p style={{fontSize:34, fontWeight:800,  marginBottom:0,  color:"white"}}>Welcome</p>
                    <p style={{ marginTop:10, fontSize:15, borderBottom:2, paddingBottom:15, borderColor:"white", color:'white', borderStyle:"solid", borderTop:0, borderLeft:0, borderRight:0, marginBottom:-20}}>Start your quest into Web 3.0</p>

                    <Lottie options={{
                        loop:true,
                        autoPlay:true,
                        animationData:web3,
                        
                        
                    }}
                        
                        />
                   
                   <p style={{ borderColor:"white",  borderStyle:"dotted", borderTop:0, borderLeft:0, borderRight:0, }}></p>

                       <Row justify='center' style={{}}>
                            <Button  type="primary"  block size="large" style={{background:"#0052CC", borderColor:"#0052CC", marginTop:15, fontSize:16, paddingLeft:50, paddingRight:50, fontWeight:500  }} onClick={createWalletModal} >
                                Create new Wallet
                            </Button>
                       </Row>
                       <Row justify='center'>
                           <p style={{fontSize:10, color:"white", marginTop:10}}>OR</p>
                       </Row>
                        
                       <Row justify='center' style={{}}>
                            <Button type="primary"  block size="large" style={{background:"#398d8f", borderColor:"#398d8f", color:"white", fontSize:16, paddingLeft:50, paddingRight:50, fontWeight:500  }}  onClick={ImportWalletModal} >
                                Import using Seed Phrase
                            </Button>
                       </Row>

                   

                  
                    


                    

                    
                </div>
               
                
                
            </Modal>

            <Modal                
                visible={createWalletVisible}
                onCancel={cancelCreateWallet}
                footer={null}
      
                closable={true}
                style={{background:"transparent", backgroundColor:"transparent", top:0}}
                bodyStyle={{ background:"transparent", backgroundColor:"transparent", paddingLeft:0, paddingRight:0, marginLeft:10, marginRight:10,}}
                maskStyle={{background:'black', opacity:.9}}     
                className='transparent'           
            >               
                {createWalletPage==0?
                    <Row justify="center" style={{marginTop:20, marginLeft:20}}>
                        <Col span={10} style={{textAlign:"center"}}>
                            <a  onClick={()=>{setCreateWalletPage(4)}}>
                                <Image width={150} height={212} preview={false} src={"https://s3.amazonaws.com/homepage.images/connectAlphaWallet.png"}></Image>
                            </a>
                        </Col>

                        <Col span={10} style={{textAlign:"center"}}>
                            <a onClick={()=>{setCreateWalletPage(1)}}>
                                <Image  width={150} preview={false}  src={"https://s3.amazonaws.com/homepage.images/connectWallet.png"}></Image>
                            </a>
                        </Col>
                        
                    </Row>

                :
                    createWalletPage==1?
                    <div style={{backgroundColor:"transparent", paddingTop:25, paddingBottom:45}}>
                    
                        <p style={{fontSize:34, fontWeight:800,  marginBottom:0,  color:"white"}}>Select A Wallet to Import</p>
                        <p style={{ fontSize:15, borderBottom:2, paddingBottom:15, borderColor:"white", color:'white', borderStyle:"solid", borderTop:0, borderLeft:0, borderRight:0, marginBottom:35}}>Import your External Wallet to Alpha Libertée</p>

                        <Row justify="center" style={{marginBottom:20}} >
                        
                        <Col span={8}  style={{textAlign:"center"}}>
                            <a onClick={()=>{
                                setWalletProvider("phantom")
                                setCreateWalletPage(2)
                            }} style={{textAlign:"center"}}>
                                <Image width={55} style={{borderRadius:27.5}} preview={false} src={"https://gblobscdn.gitbook.com/spaces%2F-MVOiF6Zqit57q_hxJYp%2Favatar-1615495356537.png?alt=media"}></Image>
                            </a>
                        </Col>

                        <Col span={8} style={{textAlign:"center"}}>
                            <a onClick={()=>{
                                setWalletProvider("solflare")
                                setCreateWalletPage(2)
                            }}>
                                <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://styles.redditmedia.com/t5_5bevfw/styles/communityIcon_hzwwd1hdtuy71.png"}></Image>
                            </a>
                        </Col>


                        <Col span={8} style={{textAlign:"center"}}>
                            <a onClick={()=>{
                                setWalletProvider("sollet")
                                setCreateWalletPage(2)
                            }} style={{textAlign:"center"}}>
                                <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://lh3.googleusercontent.com/kXlLPzMcTUFsou6MUpkdBmhrsiVuIdQxXb_hD9Qx00jRfM54lXF585lNVsbYvS0RwVKNwhiYYEUBM6AU_97DoKhQ7A=w128-h128-e365-rj-sc0x00ffffff"}></Image>
                            </a>
                        </Col>
                        
                    </Row>

                    <p style={{ borderColor:"white",  borderStyle:"dotted", borderTop:0, borderLeft:0, borderRight:0, marginTop:25 }}></p>

                    </div>
            
            :
            createWalletPage==2?
                <div style={{backgroundColor:"transparent", paddingTop:25, paddingBottom:45}}>
                    
                    <p style={{fontSize:34, fontWeight:800,  marginBottom:0,  color:"white"}}>Import your Secret Key</p>
                    <p style={{ fontSize:15, borderBottom:2, paddingBottom:15, borderColor:"white", color:'white', borderStyle:"solid", borderTop:0, borderLeft:0, borderRight:0, marginBottom:5}}>Your secret key is the password to your wallet</p>
                    
                    <Row justify="center" >
                    
                        <Col span={24}  style={{textAlign:"center"}}>
                            
                            <p style={{textAlign:"center", color:'red'}}>{errorMessage}</p>
                            <Input placeholder='Wallet Nickname' size="large" style={{background:"transparent", backgroundColor:"transparent", color:'white'}}  value={newWalletName} onChange={newWalletName => setNewWalletName(newWalletName.target.value)}></Input>

                            <TextArea
                                value={seedPhrase} 
                                onChange={seedPhrase => setSeedPhrase(seedPhrase.target.value)}
                                placeholder="Seed Phrase"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                size="large"
                                style={{background:"transparent", backgroundColor:"transparent", color:"white", marginTop:20}}
                            />

                            <Button type="primary"  block size="large" style={{background:"#0052CC", borderColor:"#0052CC", marginTop:15, fontSize:16,   }} onClick={importWallet} >
                                Import Wallet
                            </Button>
                        </Col>                     
                    
                    </Row>

                </div>

                :
                createWalletPage==3?
                    <Row justify="center"  style={{paddingTop:25, paddingBottom:25}}>
                        <Col span={24} >
                            <p style={{fontSize:34, fontWeight:800,  marginBottom:0,  color:"white"}}>Wallet Created Successfully!</p>
                            <p style={{ fontSize:15, borderBottom:2, paddingBottom:15, borderColor:"white", color:'white', borderStyle:"solid", borderTop:0, borderLeft:0, borderRight:0, marginBottom:-40}}>You will be re-directed to the wallet page</p>   
                            
                            <Lottie options={{
                                    loop:true,
                                    autoPlay:true,
                                    animationData:walletCreated,
                                    
                                }}
                                
                                
                                />
                            
                            {/*<Button type="primary" style={{backgroundColor:"#3A5DD9", borderColor:"#3A5DD9", marginLeft:25, marginTop:25}} shape="round" onClick={showWallet} >
                                Open Wallet
                            </Button>*/}                            
                        </Col>                  
                    </Row>
                :
                createWalletPage==4?
                    <div style={{paddingTop:25, paddingBottom:25 }}>
                        
                        <Row justify="center" >
                            <Col span={24}  style={{textAlign:"left"}}>
                                <p style={{fontSize:34, fontWeight:800,  marginBottom:0,  color:"white"}}>Select the Type of Wallet</p> 
                                <p style={{marginTop:10, fontSize:15, borderBottom:2, paddingBottom:15, borderColor:"white", color:'white', borderStyle:"solid", borderTop:0, borderLeft:0, borderRight:0, marginBottom:20}}>Choose the currency you want to store</p>


                                <Select
                                    showSearch
                                    className='walletType'
                                    style={{ width: '100%', background:"transparent" }}
                                    placeholder="Select your Token"
                                    defaultValue={'Solana'}
                                    onChange={handleChange}
                                    optionLabelProp="label"
                                    filterOption={(input, option) =>
                                        option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                    }
                                    size="large"

                                >
                                    <Option value="Solana" label="Solana">                                    
                                        Solana (SOL))                                   
                                    </Option>
                                    <Option value="Bitcoin" label="Bitcoin">                                      
                                        Bitcoin (BTC)                                    
                                    </Option>
                                    
                                </Select>

                                <p style={{ borderColor:"white",  borderStyle:"dotted", borderTop:0, borderLeft:0, borderRight:0, marginTop:25 }}></p>

                                <Row justify='center' style={{}}>
                                    <Button  type="primary"  block size="large" style={{background:"#0052CC", borderColor:"#0052CC", marginTop:15, fontSize:16, paddingLeft:50, paddingRight:50,  }} onClick={()=>{setCreateWalletPage(5)}} >
                                        Next
                                    </Button>
                                </Row>

                               {/* <Row justify="center" >
                        
                                  
                                    <Col span={6} style={{textAlign:"center", marginRight:20}}>
                                        <a onClick={()=>{
                                            setWalletProvider("sol")
                                            setCreateWalletPage(5)
                                        }}>
                                            <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png"}></Image>
                                        </a>
                                    </Col>


                                    <Col span={6} style={{textAlign:"center", marginLeft:20}}>
                                        <a onClick={()=>{
                                            setWalletProvider("btc")
                                            setCreateWalletPage(5)
                                        }} style={{textAlign:"center"}}>
                                            <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=018"}></Image>
                                        </a>
                                    </Col>
                                    
                                </Row>  

                                <Row justify="center" style={{marginTop:10}}>                        
                                  
                                    <Col span={6} style={{textAlign:"center", marginRight:20}}>
                                        <a >
                                            <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Ethereum-ETH-icon.png"}></Image>
                                        </a>
                                    </Col>


                                    <Col span={6} style={{textAlign:"center", marginLeft:20}}>
                                        <a  style={{textAlign:"center"}}>
                                            <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Tether-USDT-icon.png"}></Image>
                                        </a>
                                    </Col>
                                    
                                    </Row> */}
                                                        
                            </Col>                  
                        </Row>

                    </div>

                    :
                    createWalletPage==5?
                        <div style={{marginTop:20, }}>
                            
                            <Row justify="center" >
                    
                                <Col span={24}  style={{}}>
                                    <p style={{fontSize:34, textAlign:"left", fontWeight:600, marginBottom:30, color:"white", borderBottom:2, paddingBottom:15, borderColor:"white",  borderStyle:"solid", borderTop:0, borderLeft:0, borderRight:0, }}>Enter Wallet Name</p>
                                    <p style={{textAlign:"center", color:'red'}}>{errorMessage}</p>
                                    <Input placeholder='Wallet Nickname' size="large" style={{background:"transparent", backgroundColor:"transparent",color:"white"}}  value={newWalletName} onChange={newWalletName => setNewWalletName(newWalletName.target.value)}></Input>

                                    <p style={{ borderColor:"white",  borderStyle:"dotted", borderTop:0, borderLeft:0, borderRight:0, marginTop:25 }}></p>

                                    <Button  type="primary"  block size="large" style={{background:"#0052CC", borderColor:"#0052CC", marginTop:15, fontSize:16, paddingLeft:50, paddingRight:50,  }} onClick={createWallet} >
                                        Create Wallet
                                    </Button>
                                </Col>                     
                            
                            </Row>

                        </div>
                    :
                    createWalletPage==6?
                    <Row justify="center"  style={{paddingTop:25, paddingBottom:25}}>
                        <Col span={24} >
                            <p style={{fontSize:34, fontWeight:800,  marginBottom:0,  color:"white"}}>Wallet Created Successfully!</p>
                            <p style={{ fontSize:15, borderBottom:2, paddingBottom:15, borderColor:"white", color:'white', borderStyle:"solid", borderTop:0, borderLeft:0, borderRight:0, marginBottom:-80}}>You will be re-directed to the wallet page</p>   
                            
                            <Lottie options={{
                                    loop:false,
                                    autoPlay:true,
                                    animationData:success,
                                    
                                }}
                                
                                
                                />
                            
                            {/*<Button type="primary" style={{backgroundColor:"#3A5DD9", borderColor:"#3A5DD9", marginLeft:25, marginTop:25}} shape="round" onClick={showWallet} >
                                Open Wallet
                            </Button>*/}                            
                        </Col>                  
                    </Row>
                
                
                    
                
                :  
                    <div>

                    </div>
                }
                
                
            </Modal>

            <div style={{fontSize:24, fontWeight:700, color:"#7188FE", marginTop:40}}><span style={{backgroundColor:"#f8f9fa"}}>Play-to-Earn</span></div>
            <div style={{fontSize:24, fontWeight:700}}><span style={{backgroundColor:"#f8f9fa"}}>Made-to-Learn</span></div>

          
            

            <Row justify="center" style={{  alignItems:"center", marginTop:20}}>
                <Col xs={{span:20, order:2}} sm={{span:20, order:2}}  md={{order:1, span:3}} >
                    <div style={{float:"left"}}>
                        <a type="text" style={{display:"block", fontSize:24, fontWeight:700, fontFamily: 'TuskerGrotesk', color:"black", textAlign:"left", backgroundColor:"#f8f9fa"}} onClick={showSignUp}>EDUCATE</a>
                        <a type="text" style={{display:"block", fontSize:24, fontWeight:700, fontFamily: 'TuskerGrotesk', color:"black", textAlign:"left", backgroundColor:"#f8f9fa"}} onClick={showSignUp}>GAMIFY</a>
                        <a type="text" style={{display:"block", fontSize:24, fontWeight:700, fontFamily: 'TuskerGrotesk', color:"black", textAlign:"left", backgroundColor:"#f8f9fa"}}  onClick={showSignUp}>EXPLORE</a>
                        {!loggedIn?
                            <div>
                                <a type="text" style={{display:"block", fontSize:24, fontWeight:700, fontFamily: 'TuskerGrotesk', color:"black", textAlign:"left", backgroundColor:"#f8f9fa"}} onClick={showLogin}>LOG IN</a>
                                <a type="text" style={{display:"block", fontSize:24, fontWeight:700, fontFamily: 'TuskerGrotesk', color:"black", textAlign:"left", backgroundColor:"#f8f9fa"}}  onClick={showSignUp}>SIGN UP</a>
                            </div>
                        :
                            <div></div>
                        }
                        
                        {/*<a type="text" style={{display:"block", fontSize:24, fontWeight:700, fontFamily: 'TuskerGrotesk', color:"black", textAlign:"left"}}>GET THE APP</a>*/}
                        {walletExists&&loggedIn?
                            <a href={"/wallet"}>
                                <Button type="primary" shape="round"  size={"large"} style={{background:"#2B00F9", marginTop:20,}}block  >Open Wallet</Button>         
                            </a>            
                            
                        :loggedIn&& walletExists==false?
                            <Button type="primary" shape="round"  size={"large"} style={{background:"#2B00F9", marginTop:20,}}block onClick={showWallet} >Connect</Button>
                        :   
                            <Button type="primary" shape="round"  size={"large"} style={{background:"#2B00F9", marginTop:20,}}block onClick={showSignUp} >Connect</Button>
                        }
                                         

                        <a type="text" style={{display:"block", fontSize:12, height:20, marginTop:20, color:"#00000073", textAlign:"left", backgroundColor:"#f8f9fa"}} href={"https://www.instagram.com/alphalibertee/?hl=en"}>Instagram</a>    
                        <a type="text" style={{display:"block", fontSize:12, height:20, color:"#00000073", textAlign:"left", backgroundColor:"#f8f9fa"}} href={"mailto:kanan@alphalibertee.com"} >Email</a>    
                        <a type="text" style={{display:"block", fontSize:12, height:20, color:"#00000073", textAlign:"left", backgroundColor:"#f8f9fa"}}>Privacy Policy</a>    

                    </div>
                    
                </Col>               
                <Col xs={{span:24, order:1}} sm={{span:24, order:1}}   md={{order:2, span:8}}>
                    <Image preview={false}
                        
                        src={splash}
                    />  
                </Col>

                <Col span={24} order={3} style={{backgroundColor:"#7188FE", marginTop:20}}>
                    <Image    preview={false}                      
                        src={sponsors}
                    /> 
                </Col>

            </Row>
            <ParticlesBg type="cobweb" num={size.width>767 ? 50 : 15} color='#7188FE' bg={true} />
        </>
    );
};

export default HomePage;

