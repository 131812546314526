import React from 'react';
import { Result, Button } from 'antd';
/**
 * Application home page component
 * @returns {JSX}
 */
const HomePage = () => {

    return (
        <>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you are trying to reach does not exist on our server."
                extra={<Button type="primary" href="/">Back Home</Button>}
            />
        </>
    );
};

export default HomePage;