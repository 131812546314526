import React, { useContext, useState, useEffect, useCallback, useRef  } from 'react';
import { Row, Col, Divider, Button,Image, Modal, Input, Select, message, Space,  } from 'antd';
import Textarea from 'react-expanding-textarea'

import walletMenuIcon from '../assets/walletMenuIcon.png';

import DaisyProfile from '../assets/daisySuggestion.png';


import logo from '../assets/alphaLogo.jpg';
import congratulations from '../assets/congratulations.png';
import { UserContext } from "../context/UserContext";
import { SendOutlined } from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { AudioOutlined } from '@ant-design/icons';
import TextareaAutosize from 'react-textarea-autosize';

import Dictaphone from '../components/Dictaphone'

/**
 * Application home page component
 * @returns {JSX}
 */
const Daisy = () => {
    const [user, setUser] = useContext(UserContext);
    const [loginVisible, setLoginVisible] = useState(false);
    const [signUpVisible, setSignUpVisible] = useState(false);
    const [createWalletVisible, setCreateWalletVisible] = useState(false)
    const [createWalletPage, setCreateWalletPage] = useState(0)
    const [walletProvider, setWalletProvider] = useState("");
    const [seedPhrase, setSeedPhrase] = useState("")
    const [newWalletName, setNewWalletName] = useState("")    
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState("")
    const [errorMessage, setErrorMessage] = useState("");
    const [walletVisible, setWalletVisible] = useState(false);
    const [solWalletList, setSolWalletList] = useState([]);
    const [btcWalletList, setBtcWalletList] =useState([]);
    const { Option, OptGroup } = Select;
    const [selectedWallet,setSelectedWallet]=useState("");
    const[tokenAmount, setTokenAmount] = useState(0);
    const[usdAmount, setUSDAmount] = useState(0);
    const[activeWallet, setActiveWallet] = useState("SOL");
    const [publicAddress, setPublicAddress] = useState("");
    const [tokenImage, setTokenImage] = useState("https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png");
    const [tokenType, setTokenType] = useState("Solana");
    const [marketPrice,setMarketPrice] = useState(0);
    const [marketPercent,setMarketPercent] = useState(0);
    const [walletExists, setWalletExists] = useState(false)
    const [sendTransaction, setSendTransaction] = useState(false)
    const [sendTransferAddress, setSendTransferAddress] = useState("")
    const [sendTransferAmount, setSendTransferAmount] = useState("0")
    const [sendTransactionPage, setSendTransactionPage] = useState(0)
    const { TextArea } = Input;
    const [receiveVisible, setReceiveVisible] = useState(false)
    const size = useWindowSize();
    const [data,setData] = useState([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState("")
    const [betPlaced, setBetPlaced] = useState(false);
    const [date,setDate] = useState("Thursday");
    const [mode,setMode] = useState("Combined");
    const [askDaisy, setaskDaisy] = useState("")
    const [daisyTyping, setDaisyTyping] = useState(false)
    const [outputQuestionsArray,setoutputQuestionsArray] = useState([]);  
    const [daisyMode,setDaisyMode] = useState("askDaisy")
    const color = () => Math.ceil(Math.random() * 250);
    const itemHeight = 50;
    const { Search } = Input;
    const textareaRef = useRef(null)
    const [question, setQuestion] = useState("");

    


    

    useEffect(() => {
        if(localStorage.getItem("username")){
            
            setUsername(localStorage.getItem("username"))
            setLoggedIn(true)
            loadBtcWallets()
            loadSolWallets()
            
        }
      
        



        

    }, [username]);


    


    const state = {
        items: [
          {
            content: 'What is crypto currency?',
            
          },
          {
            content: 'What is Solana?',
            
          },
          {
            content: 'What is Bitcoin?',
            
          },
          {
            content: 'What is an NFT?',
            
          },
          {
            content: 'How do I buy an NFT?',
            
          },
          {
            content: 'What is the best play-to-earn game?',
            
          },
          {
            content: 'How can I make money in Web 3.0',
            
          },
          
        ]
      }

    const handleDelete = itemToDelete => this.setState(({ items }) => ({ items: items.filter(item => item !== itemToDelete) }))

    const suffix = (
        <AudioOutlined
          style={{
            fontSize: 16,
            color: '#1890ff',
          }}
        />
      );
      
      const onSearch = value => fetchDaisy(value);

    const fetchDaisy = async (questionAsked) => {  
        
        questionAsked=questionAsked.toString()
        console.log(questionAsked)

        if(questionAsked=="How to buy an NFT?"||questionAsked.toLowerCase()=="how to buy an nft?"||questionAsked.toLowerCase()=="how to buy an nft"||questionAsked.toLowerCase()=="how to buy nfts?"||questionAsked.toLowerCase()=="how to buy nfts"){
          setoutputQuestionsArray(prevQuestions => [...prevQuestions,{
            id:"user",
            value:questionAsked
          }])
    
          setoutputQuestionsArray(prevQuestions => [...prevQuestions,{
            id:"daisy",
            value:"NFT's are non fungible tokens, which are unqiue and non-interchangeable units of data on the blockchain. They are often associated with digital files such as photos, videos and audio."
          }])
        }
    
        else if(questionAsked=="How to buy Bitcoin?"||questionAsked.toLowerCase()=="how to buy bitcoin?"||questionAsked.toLowerCase()=="how to buy bitcoin"||questionAsked.toLowerCase()=="how to purchase bitcoin?"||questionAsked.toLowerCase()=="how to purchase bitcoin"||questionAsked.toLowerCase()=="how to purchase btc?"||questionAsked.toLowerCase()=="how to purchase btc"){
          setoutputQuestionsArray(prevQuestions => [...prevQuestions,{
            id:"user",
            value:questionAsked
          }])
    
          setoutputQuestionsArray(prevQuestions => [...prevQuestions,{
            id:"daisy",
            value:"Bitcoin is a type of cryptocurrency: Balances are kept using public and private 'keys,' which are long strings of numbers and letters linked through the mathematical encryption algorithm that was used to create them."
          }])
        }
    
        else{
          setaskDaisy(""); 
          //Keyboard.dismiss();
          setDaisyTyping(true)
      
          var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            
          };
          
          console.log(questionAsked)
          setoutputQuestionsArray(prevQuestions => [...prevQuestions,{
            id:"user",
            value:questionAsked
          }])
      
          setTimeout(async () => {
            //scrollEnd();
          }, 200);
          
          await fetch(`https://api.alphalibertee.com/response?question=${questionAsked.replace(" ","%20")}&username=${user.username}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                  
              console.log(result)
              setDaisyTyping(false)
      
              
      
              setoutputQuestionsArray(prevQuestions => [...prevQuestions,{
                id:"daisy",
                value:result
              }])
            
              
      
            })
            .catch(error => console.log('error', error))
      
            setTimeout(async () => {
              setQuestion("")
              //scrollEnd();
            }, 200);
        }
    
    
    
      
        
      }



      const outputQuestions = () => {
        return(
          outputQuestionsArray.map((question,index, array)=>(
            (question["id"]=="user"?
              <div style={{flexDirection:"row", justifyContent:"flex-end", }} key={index}>
                
                    
                <div style={{                 
                           
                  
                }}>
                  <div style={{borderRadius:20, backgroundColor: "#0038FF", padding:15, borderBottomRightRadius:0 }}>
                    <span style={{   fontSize: 14, color: "white", textAlign:"right", paddingHorizontal:10}}>
                      {question["value"]}
                    </span>
                  </div>
                  
    
                  <p style={{justifyContent:"flex-end", textAlign:"right", marginRight:5, marginTop:5, fontFamily:"SF_Pro_500Regular", fontSize: 12, color:"black"}}>
                      { 
                        //moment().format("h:mm a")
                      }
                      </p>
                </div>
    
                {/*<Image source={{uri:profilePicURI + '?' + new Date()}}   style={{ height:30,width:30, margin:2, marginLeft:7,
                                        borderRadius:20, }}></Image> */}
              </div>
              :
              <div key={index} style={{flexDirection:"row", marginRight:"20%", marginBottom:20 }}>  
                <div style={{paddingBottom:10, alignSelf:'flex-end', marginRight:5}}>
                  {/*<Image style={{alignContent:"flex-end", alignSelf:'flex-end'}}source={require("../../assets/daisy/daisyIcon.png")} style={{height:37,width:37  }}></Image>*/}
                </div>
                
                <div style={{              
    
                  
                }}>
                  
                  <div style={{ backgroundColor: "#F2F2F2", padding:15,  borderRadius: 20, borderBottomLeftRadius:0, marginTop:10, }}>
                    <span style={{  fontSize: 14, color: "black", justifyContent:"center" }}>{question["value"]}</span>
                    {array[index-1]["value"]=="How to buy Bitcoin?"||array[index-1]["value"].toLowerCase()=="how to buy bitcoin?"||array[index-1]["value"].toLowerCase()=="how to buy bitcoin"||array[index-1]["value"].toLowerCase()=="how to purchase bitcoin?"||array[index-1]["value"].toLowerCase()=="how to purchase bitcoin"||array[index-1]["value"].toLowerCase()=="how to purchase btc?"||array[index-1]["value"].toLowerCase()=="how to purchase btc"?
                    <p style={{ fontSize: 14, color: "#0000FF", justifyContent:"center", marginTop:5}}>Check out our SatStreet quest to get started on buying Bitcoin!</p>
                    :array[index-1]["value"]=="How to buy an NFT?"||array[index-1]["value"].toLowerCase()=="how to buy an nft?"||array[index-1]["value"].toLowerCase()=="how to buy an nft"||array[index-1]["value"].toLowerCase()=="how to buy nfts"?
                    <p style={{ fontSize: 14, color: "#0000FF", justifyContent:"center", marginTop:5}}>Check out our Solanart quest to get started on buying NFT's!</p>
                    : <div></div>
                  }
                                         
                  </div>
                  
                  <p style={{textAlign:"left", marginLeft:10, marginTop:5, fontSize: 12, color:"black"}}>
                    { 
                        //moment().format("h:mm a")
                    }</p>
                </div>
    
                
              </div>
            )
          ))
        )    
      }

    const cancelTransaction = () => {
        setSendTransaction(false); 
    };

    const showTransaction = () => {
        setSendTransaction(true); 
    };

    const cancelWallet = () => {
        setWalletVisible(false); 
    };

    const showRecieveModal = () =>{
        setReceiveVisible(true)
    }

    const cancelRecieveModal = () =>{
        setReceiveVisible(false)
    }
    
   

    
    const cancelCreateWallet = () => {
        setCreateWalletVisible(false);
        setCreateWalletPage(0) 
        setErrorMessage("")
    };

        const ImportWalletModal = () => {
        cancelWallet()
        setCreateWalletVisible(true)  
        setCreateWalletPage(1)      
    }

    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
          width: undefined,
          height: undefined,
        });
        useEffect(() => {
          // Handler to call on window resize
          function handleResize() {
            // Set window width/height to state
            setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            });
          }
          // Add event listener
          window.addEventListener("resize", handleResize);
          // Call handler right away so state gets updated with initial window size
          handleResize();
          // Remove event listener on cleanup
          return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
      }

    const createWallet = async () => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            
        };      
          
          await fetch(`https://api.alphalibertee.com/${walletProvider}/createWallet?username=${username}&wallet_name=${newWalletName}&platform=`, options)
              .then(response => response.json())
              .then(data => {
                if(data["success"]){
                  setErrorMessage("")
                  loadBtcWallets()
                  loadSolWallets()
                
                  setTimeout(() => {
                    walletLoad([0,newWalletName])           
                    setSeedPhrase("")
                    setNewWalletName("")
                  }, 2000);                  
                  
                  setCreateWalletPage(6)
                  
                }
                else{
                  setErrorMessage(data["Error"])
                }
              });
    };

    const importWallet = async () => {
        const options = {
            method: 'POST',
        };

        let pathNumber = 0;

        if(walletProvider=="phantom" || walletProvider=="sollet"){
            pathNumber = 0;
        }

        if(walletProvider=="solflare"){
         pathNumber = 1;
        }

        await fetch(`https://api.alphalibertee.com/sol/createWallet?username=${username}&wallet_name=${newWalletName}&privateKey=${seedPhrase}&platform=${pathNumber}`, options)
            .then(response => response.json())
            .then(data => {
            
            if(data["success"]){
                setErrorMessage("")
                setCreateWalletPage(3)

            
                    loadBtcWallets()
                    loadSolWallets()
                  
            
               
                    walletLoad([0,newWalletName])           
                    setSeedPhrase("")
                    setNewWalletName("")
              
                    setTimeout(() => {
                        walletLoad([0,newWalletName])           
                        setSeedPhrase("")
                        setNewWalletName("")
                      }, 2000); 

                      setTimeout(() => {
                        walletLoad([0,newWalletName])           
                        setSeedPhrase("")
                        setNewWalletName("")
                      }, 2000); 

                      
                

                
                
            }
            else{
                setErrorMessage(data["error"])                
            }
            
            
            });
    };


    const publicAddressCopy = () => {
        navigator.clipboard.writeText(publicAddress)
        message.success('Address has been copied!',1);
    };

    const loadSolWallets = async () => {
        const options = {
            method: 'POST',                 
        };
         
        await fetch(`https://api.alphalibertee.com/sol/getAllWallet?username=${username}`, options)
        .then(response => response.json())
            .then(data => {
           // console.log(data)
            if(data["wallets"].length>0){      
                walletLoad([0,data["wallets"][0][0]])         
                setSolWalletList(data["wallets"])
                setWalletExists(true)   
                      
            }

            return true 
            //console.log(solWalletList)     
        });
    }

    const loadBtcWallets =async () => {
        const options = {
            method: 'POST',                 
        };
         
        await fetch(`https://api.alphalibertee.com/btc/getAllWallet?username=${username}`, options)
        .then(response => response.json())
            .then(data => {
          //  console.log(data)
            if(data["wallets"].length>0){    
                walletLoad([0,data["wallets"][0][0]])           
                setBtcWalletList(data["wallets"])
                setWalletExists(true)          
            }      
            return true        
                
        }); 
        
    }

    const walletLoad =async (walletInfo) => {
        
        
        const options = {
            method: 'POST',                 
        };
        
        if(walletInfo[0]==0){
           // console.log("sol")
            await fetch(`https://api.alphalibertee.com/sol/solBalance?username=${username}&wallet_name=${walletInfo[1]}&clientType=0`, options)
            .then(response => response.json())
                .then(data => {
                getSolanaPrice()
                //console.log(data["balance"]["SOL"])
                setTokenAmount(data["balance"]["SOL"]["amount"].toFixed(2))  
                setActiveWallet(walletInfo[1])  
                setUSDAmount(data["balance"]["SOL"]["converted"].toFixed(2))
                setPublicAddress(data["balance"]["SOL"]["walletAddress"])
                setTokenImage("https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png")   
                setTokenType("Solana")   
            });
        }
        else if(walletInfo[0]==2){
            createWalletModal()
        }
        else{            
           // console.log("BTC")
            await fetch(`https://api.alphalibertee.com/btc/balance?username=${username}&wallet_name=${walletInfo[1]}`, options)
            .then(response => response.json())
                .then(data => {
                    //console.log(data["BTC"])
                    getBTCPrice()
                    setTokenType("Bitcoin")
                    setTokenAmount(data["BTC"]["amount"])    
                    setActiveWallet(walletInfo[1])
                    setUSDAmount(data["BTC"]["converted"])
                    setPublicAddress(data["BTC"]["walletAddress"])
                    setTokenImage("https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=018")
                      
            });
        }
        
    }

    const createWalletModal = () => {
        cancelWallet()
        setCreateWalletVisible(true)        
    }

    const getSolanaPrice = async () => {
        const options = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          
        };
    
        await fetch(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=solana`, options)
            .then(response => response.json())
            .then(data => {
                setMarketPrice(data[0]["current_price"])
              setMarketPercent(data[0]["price_change_percentage_24h"].toFixed(2));
             
            });
      };
    
      const getBTCPrice = async () => {
        const options = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          
        };
    
        await fetch(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin`, options)
            .then(response => response.json())
            .then(data => {
              setMarketPrice(data[0]["current_price"])
              setMarketPercent(data[0]["price_change_percentage_24h"].toFixed(2));
             
            });
      };



      

        const transactTransaction = async () => {
               
            const options = {
                method: 'POST',                 
            };            
          
            await fetch(`https://api.alphalibertee.com/sol/solTransfer?username=${username}&wallet_name=${activeWallet}&receiver=${sendTransferAddress}&amount=${sendTransferAmount}&clientType=0`, options)
            .then(response => response.json())
                .then(data => {
                if(data["success"]){
                    setSendTransactionPage(2)
                    setErrorMessage("")
                    setSendTransferAddress("")
                    setSendTransferAmount("")
                }
                else{
                    setErrorMessage(data["error"])
                }
            });       
        };


    const checkLogin = () => {

    }
        
    const walletList = () => {
        return(
            <Select className='walletSelect' defaultActiveFirstOption={true} size={"large"} style={{ width: 200, }}  value={activeWallet} onSelect={(value => walletLoad(value))}>
      
                {
                   solWalletList!=[]?
                        solWalletList.map((wallet,index)=>(
                            <Option  key={"solWallet"+index} title={wallet[0]} value={[0,wallet[0]]} >
                                <p style={{color:"black", fontWeight:600}}>SOL - {wallet[0]}</p>
                                <p>{wallet[1].substring(0, 4)}...{wallet[1].slice(-4)}</p>
                            </Option>
                        ))
                    :<Row></Row>
                }

         

             
                <Option value={[2,"createWallet"]}>+ Create/Connect New Wallet</Option>
            </Select>
        )
        
            
    }



    return (
        <>          
            <Modal                
                visible={createWalletVisible}
                onCancel={cancelCreateWallet}
                footer={null}
                 
                centered 
                closable={true}
                style={{background:"transparent", backgroundColor:"transparent"}}
                bodyStyle={{ background:"transparent", backgroundColor:"transparent",paddingLeft:0, paddingRight:0}}
                maskStyle={{background:'black', opacity:.9}}     
                className='transparent'      
       
            >               
                {createWalletPage==0?
                    <Row justify="center" style={{marginTop:20, marginLeft:20}}>
                        <Col span={10} style={{textAlign:"center"}}>
                            <a  onClick={()=>{setCreateWalletPage(4)}}>
                                <Image width={150} height={212} preview={false} src={"https://s3.amazonaws.com/homepage.images/connectAlphaWallet.png"}></Image>
                            </a>
                        </Col>
                        

                        <Col span={10} style={{textAlign:"center"}}>
                            <a onClick={()=>{setCreateWalletPage(1)}}>
                                <Image  width={150} preview={false}  src={"https://s3.amazonaws.com/homepage.images/connectWallet.png"}></Image>
                            </a>
                        </Col>
                        
                    </Row>

                :
                    createWalletPage==1?
                    <div style={{marginTop:20 }}>
                        <h1 style={{textAlign:"center", fontWeight:600, marginBottom:30, color:'white'}}>Select a wallet to import!</h1>
                        <Row justify="center" style={{marginBottom:20}} >
                        
                        <Col span={8}  style={{textAlign:"center"}}>
                            <a onClick={()=>{
                                setWalletProvider("phantom")
                                setCreateWalletPage(2)
                            }} style={{textAlign:"center"}}>
                                <Image width={55} style={{borderRadius:27.5}} preview={false} src={"https://gblobscdn.gitbook.com/spaces%2F-MVOiF6Zqit57q_hxJYp%2Favatar-1615495356537.png?alt=media"}></Image>
                            </a>
                        </Col>

                        <Col span={8} style={{textAlign:"center"}}>
                            <a onClick={()=>{
                                setWalletProvider("solflare")
                                setCreateWalletPage(2)
                            }}>
                                <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://styles.redditmedia.com/t5_5bevfw/styles/communityIcon_hzwwd1hdtuy71.png"}></Image>
                            </a>
                        </Col>


                        <Col span={8} style={{textAlign:"center"}}>
                            <a onClick={()=>{
                                setWalletProvider("sollet")
                                setCreateWalletPage(2)
                            }} style={{textAlign:"center"}}>
                                <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://lh3.googleusercontent.com/kXlLPzMcTUFsou6MUpkdBmhrsiVuIdQxXb_hD9Qx00jRfM54lXF585lNVsbYvS0RwVKNwhiYYEUBM6AU_97DoKhQ7A=w128-h128-e365-rj-sc0x00ffffff"}></Image>
                            </a>
                        </Col>
                        
                    </Row>

                    </div>
            
            :
            createWalletPage==2?
                <div style={{marginTop:40, }}>
                    
                    <Row justify="center" >
                    
                        <Col span={20}  style={{textAlign:"center"}}>
                            <p style={{textAlign:"center", fontWeight:600, color:'white'}}>Import your Secret Key</p>
                            <p style={{textAlign:"center", color:'red'}}>{errorMessage}</p>
                            <Input placeholder='Wallet Nickname' size="large" style={{background:"transparent", backgroundColor:"transparent", color:"white"}}  value={newWalletName} onChange={newWalletName => setNewWalletName(newWalletName.target.value)}></Input>

                            <TextArea
                                value={seedPhrase} 
                                onChange={seedPhrase => setSeedPhrase(seedPhrase.target.value)}
                                placeholder="Seed Phrase"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                size="large"
                                style={{marginTop:20,background:"transparent", backgroundColor:"transparent", color:"white"}}
                            />

                            <Button type="primary" style={{backgroundColor:"#3A5DD9", borderColor:"#3A5DD9", marginLeft:25, marginTop:25}} shape="round" onClick={importWallet} >
                                Import Wallet
                            </Button>
                        </Col>                     
                    
                    </Row>

                </div>

                :
                createWalletPage==3?
                    <div style={{marginTop:40, }}>
                        
                        <Row justify="center" >
                            <Col span={20}  style={{textAlign:"center"}}>
                                <p style={{textAlign:"center", fontWeight:600, color:'white'}}>Wallet Imported Successfully!</p>   
                                <Button type="primary" style={{backgroundColor:"#3A5DD9", borderColor:"#3A5DD9", marginLeft:25, marginTop:25}} shape="round" onClick={cancelCreateWallet} >
                                    Open Wallet
                                </Button>                            
                            </Col>                  
                        </Row>

                    </div>
                :
                createWalletPage==4?
                    <div style={{marginTop:20, paddingBottom:20 }}>
                        
                        <Row justify="center" >
                            <Col span={20}  style={{textAlign:"center"}}>
                                <h2 style={{textAlign:"center", fontWeight:600, marginBottom:20, color:'white'}}>Select the type of Wallet</h2> 

                                <Row justify="center" >
                        
                                  
                                    <Col span={6} style={{textAlign:"center"}}>
                                        <a onClick={()=>{
                                            setWalletProvider("sol")
                                            setCreateWalletPage(5)
                                        }}>
                                            <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png"}></Image>
                                        </a>
                                    </Col>


                                    <Col span={6} style={{textAlign:"center"}}>
                                        <a onClick={()=>{
                                            setWalletProvider("btc")
                                            setCreateWalletPage(5)
                                        }} style={{textAlign:"center"}}>
                                            <Image  width={55} style={{borderRadius:27.5}} preview={false}  src={"https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=018"}></Image>
                                        </a>
                                    </Col>
                                    
                                </Row>  
                                                        
                            </Col>                  
                        </Row>

                    </div>

                    :
                    createWalletPage==5?
                        <div style={{marginTop:20, }}>
                            
                            <Row justify="center" >
                    
                                <Col span={20}  style={{textAlign:"center"}}>
                                    <p style={{textAlign:"center", fontWeight:600, color:'white'}}>Select Wallet Name</p>
                                    <p style={{textAlign:"center", color:'red'}}>{errorMessage}</p>
                                    <Input placeholder='Wallet Nickname' size="large" style={{background:"transparent", backgroundColor:"transparent", color:"white"}}  value={newWalletName} onChange={newWalletName => setNewWalletName(newWalletName.target.value)}></Input>

                                    

                                    <Button type="primary" style={{backgroundColor:"#3A5DD9", borderColor:"#3A5DD9", marginLeft:25, marginTop:25}} shape="round" onClick={createWallet} >
                                        Create Wallet
                                    </Button>
                                </Col>                     
                            
                            </Row>

                        </div>
                    :
                    createWalletPage==6?
                        <div style={{marginTop:40, }}>
                            
                            <Row justify="center" >
                                <Col span={20}  style={{textAlign:"center"}}>
                                    <h2 style={{textAlign:"center", fontWeight:600, color:'white'}}>Wallet Created Successfully!</h2>   
                                    <Button type="primary" style={{backgroundColor:"#3A5DD9", borderColor:"#3A5DD9", marginLeft:25, marginTop:25}} shape="round" onClick={cancelCreateWallet} >
                                        Open Wallet
                                    </Button>                            
                                </Col>                  
                            </Row>
    
                        </div>
                
                
                    
                
                :  
                    <div>

                    </div>
                }
                
                
            </Modal>
                    
                    <Modal                
                        visible={sendTransaction}
                        onCancel={cancelTransaction}
                        footer={null}
                        closable={false}
                        bodyStyle={{paddingLeft:0, paddingRight:0, paddingTop:0}}               
                    >   
                    
                    {
                        sendTransactionPage==0?
                        <div>
                            <p style={{textAlign:"center", fontSize:20, fontWeight:600, color:"white", backgroundColor:"#587BF8", paddingTop:15, paddingBottom:15}}>Withdraw</p>
                            <Row justify="center" >
                                <Col span={20}  style={{textAlign:"center"}}>
                                    
                                    <p style={{textAlign:"center", color:'red'}}>{errorMessage}</p>
                                    <Input placeholder='Public Address' size="large" style={{marginBottom:20}}  value={sendTransferAddress} onChange={sendTransferAddress => setSendTransferAddress(sendTransferAddress.target.value)}></Input>

                                    <Row justify='center' align="middle"  style={{marginTop:20}}>
                                        <Col span={6}>
                                            <p style={{fontSize:18, fontWeight:600,  padding:0, marginBottom:0, marginRight:20}}>Amount: ({tokenType == "Solana" ? "SOL" : "BTC"})</p>
                                        </Col>
                                        <Col span={10}>
                                            <Input placeholder='Public Address' size="large" style={{}}  value={sendTransferAmount} onChange={sendTransferAmount => setSendTransferAmount(sendTransferAmount.target.value)}></Input>
                                        </Col>
                                    </Row>

                                    <Button  style={Number(sendTransferAmount) <= 0? { backgroundColor:"grey", borderColor:"grey", marginLeft:25, marginTop:25, color:"white"} : {backgroundColor:"#587BF8", borderColor:"#587BF8", marginLeft:25, marginTop:25, color:"white"}} shape="round" disabled={Number(sendTransferAmount) > 0? false : true} onClick={()=>{setSendTransactionPage(1)}} >
                                        Send Transaction
                                    </Button>
                                </Col>                     
                            
                            </Row>
                        </div>
                        
                        : sendTransactionPage==1?
                        <div>
                            
                            <Row  align="middle"  style={{backgroundColor:"#587BF8", paddingTop:15, paddingBottom:15}}>
                                <Col style={{textAlign:"center"}} span={6}>
                                    <a style={{color:"white",}} onClick={()=>{setSendTransactionPage(0)}}>Edit</a>
                                </Col>
                                <Col  style={{textAlign:"center"}} span={12}>
                                    <span style={{textAlign:"center", fontSize:20, fontWeight:600, color:"white", }}>Withdraw</span>
                                </Col>
                                <Col  style={{textAlign:"center"}}  span={6}>
                                    <a style={{color:"white"}} onClick={()=>{
                                        setSendTransactionPage(0)
                                        cancelTransaction()}}>Cancel</a>
                                </Col>                                
                            </Row>
                            
                            <div>
                                <Row justify='center' style={{marginTop:20}}>
                                    <div style={{borderWidth:1, borderColor:"#B0B0B0", borderStyle:"solid", paddingLeft:10, paddingRight:10, marginRight:15, backgroundColor:'#FDFDFD'}}>
                                        <p style={{margin:0, padding:0, color:"#737A82", fontSize:12}}>Wallet</p>
                                        <p style={{margin:0, padding:0, color:"#737A82", fontSize:12}}>{publicAddress.substring(0, 4)}...{publicAddress.slice(-4) }</p>
                                    </div>
                                    <span style={{fontSize:25}}>-</span>
                                    <div style={{borderWidth:1, borderColor:"#B0B0B0", borderStyle:"solid", paddingLeft:10, paddingRight:10, marginLeft:15, backgroundColor:'#FDFDFD'}}>
                                        <p style={{margin:0, padding:0, color:"#737A82", fontSize:12}}>{sendTransferAddress.substring(0, 4)}...{sendTransferAddress.slice(-4)}</p>
                                    </div>
                                </Row>
                            </div>
                            
                            <div style={{textAlign:'center', marginTop:15}}>
                                <p style={{color:"red"}}>{errorMessage}</p>
                                <h1 style={{fontSize:51, marginBottom:0, }}>{sendTransferAmount}</h1>
                                <p style={{marginTop:0, fontSize:16, color:"#737A82"}}>${Number(Number(sendTransferAmount)*Number(marketPrice)).toFixed(2)} USD</p>
                                
                                <div style={{borderWidth:1.5, borderBottomWidth:0, borderRightWidth:0, borderLeftWidth:0, borderColor:"#C6C9CD", borderStyle:"solid", paddingTop:5, marginLeft:30, marginRight:30, marginTop:25, }}>
                                    <Row justify='space-between' style={{}}>
                                        <Col>
                                            <span style={{fontWeight:600, color:"#737A82", fontSize:16 }} >Estimated Fees</span>
                                        </Col>
                                        <Col>
                                            <span style={{color:"#737A82", fontSize:16 }}>0.00125SOL</span> <span style={{fontWeight:600, color:"black", fontSize:16 }}> ${(Number(0.00125) * Number(marketPrice)).toFixed(2)}</span>
                                        </Col>
                                    </Row>                                  
                                </div>
                                <div style={{borderWidth:1.5, borderBottomWidth:0, borderRightWidth:0, borderLeftWidth:0, borderColor:"#C6C9CD", borderStyle:"solid", paddingTop:5, marginLeft:30, marginRight:30, marginTop:10, }}>
                                    <Row justify='space-between' style={{}}>
                                        <Col>
                                            <span style={{fontWeight:600, color:"#737A82", fontSize:16 }} >Total</span>
                                        </Col>
                                        <Col>
                                            <span style={{color:"#737A82", fontSize:16 }}>{Number(sendTransferAmount) - Number(0.00125)}SOL</span> <span style={{fontWeight:600, color:"black", fontSize:16 }}> ${((Number(sendTransferAmount) - Number(0.00125)) * Number(marketPrice)).toFixed(2)}</span>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{borderWidth:1.5, borderBottomWidth:0, borderRightWidth:0, borderLeftWidth:0, borderColor:"#C6C9CD", borderStyle:"solid", paddingTop:5, marginLeft:30, marginRight:30, marginTop:10, }}></div>

                                <Button type="primary" size="large" style={{backgroundColor:"#587BF8", borderColor:"#587BF8", marginLeft:25, marginTop:25}} shape="round" onClick={()=>{transactTransaction()}} >
                                    Send Transaction
                                </Button>

                            </div>


                        </div>
                        : sendTransactionPage==2?
                        <div style={{textAlign:"center", paddingTop:25}}>
                            <p style={{fontSize:32, fontWeight:600, }}>Transaction Complete</p>

                            <Image style={{marginBottom:20, display:"block"}} preview={false} src={congratulations}></Image>

                            <Button type="primary" size='large' style={{backgroundColor:"#587BF8", borderColor:"#587BF8", display:"block", marginRight:"auto", marginLeft:"auto"  }} shape="round" onClick={()=>{setSendTransactionPage(0)
                                cancelTransaction()}} >
                                Return
                            </Button>


                        </div>
                        :
                        <Row>

                        </Row>
                    }
                                
                
                   
                    </Modal>

                    <Modal                
                        visible={receiveVisible}
                        onCancel={cancelRecieveModal}
                        footer={null}
                        closable={false}
                        bodyStyle={{paddingLeft:0, paddingRight:0, }}               
                    >   
                        <Row justify='center'>

                            <h1 >{activeWallet}</h1>

                            

                        </Row>

                        <Row justify='center'>
                            <QRCode style={{}} size={128} value={publicAddress}> </QRCode>  
                        </Row>

                        <Row justify="center">
                            <a style={{borderWidth:1, borderColor:"black", borderStyle:"solid", marginTop:30, paddingRight:10, paddingLeft:10, paddingTop:10, paddingBottom:10, color:'black'}} onClick={publicAddressCopy}>
                                {publicAddress}
                            </a>
                        </Row>
                  
                                
                
                   
                    </Modal>

                        <Row style={{ backgroundColor:"#587BF8",  diplay:"flex", height:"100%"}}>
                            <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 2 }} lg={{ span: 2 }} style={{flex:1} }>
                                <div>
                                    <Image src={walletMenuIcon} preview={false} style={{paddingTop:80, paddingLeft:'10%', paddingRight:"10%"}}></Image>
                                </div>

                                <div style={{borderBottomWidth:5, borderColor:"white", marginTop:10, marginBottom:10, marginLeft:"10%", marginRight:"10%"}}>
                                    
                                </div>
                            </Col>

                            <Col style={{flex:1, height:"100%"}} xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 22 }} lg={{ span: 22 }}>
                                <div style={{backgroundColor:"white", paddingTop:10, flex:1, height:"100%"}}>
                                    <div  style={{paddingLeft:"5%", paddingRight:"5%", borderBottomWidth:1, borderColor:"black", overflowY:"hidden"}}>    
                                        <Row align='middle'  >
                                            <Col >
                                                <Image src={DaisyProfile} preview={false} style={{backgroundColor:"white", borderRadius:50, height: 50, width:50}}></Image>                                                                                           
                                            </Col>
                                            <Col>
                                                <span style={{fontSize:"2em", fontWeight:600, marginLeft:20}}>Daisy</span>
                                            </Col>
                                                                                    
                                        </Row>
                                       
                                    </div>
                                    <div  style={{backgroundColor:"white",  paddingLeft:"10%", paddingRight:"10%",  display:"flex", overflowY:"true", flexDirection:"column", maxHeight:"72%", minHeight:"72%", paddingTop:20, justifyContent:"space-between"}}>                                    
                                        <div style={{overflow:"auto", height:"100%"}}>
                                            {outputQuestions()}
                                            {daisyTyping?
                                                <div style={{flexDirection:"row"}}>
                                                    <p style={{ color:"grey"}}>Daisy is typing</p>
                                                
                                                </div>
                                            :
                                                <div></div>
                                            }                                          


                                        </div>                                        
                                    </div>

                                    <div>
                                        {/*<Dictaphone></Dictaphone>*/}
                                    </div>

                                    <div>
                                        <div>
                                            <div style={{marginLeft:10, marginRight:10, display:'flex', justifyContent:"space-between", alignItems:"center"}}>
                                                <TextareaAutosize 
                                                    onChange = {question => setQuestion(question.target.value)}
                                                    value={question}
                                                    
                                                    style={{ borderRadius:10, padingLeft:10, paddingRight:10, width:"-webkit-fill-available"}}
                                                />
                                                <Button style={{marginLeft:10, backgroundColor:"#5475e2", borderRadius:40}} onClick={()=>{fetchDaisy(question)}}>
                                                    <SendOutlined style={{color:"white"}}/>
                                                </Button>
                                            </div>
                                            

                                                

                                            
                                            
                                           
                                        </div>
                                    </div>

                                 
                                    
                                    
                                    

                                    

                                    
                                    

                                       
                                        
                                  
                                            

                                                                        
                                </div>

                            </Col>

                        
                            
                        </Row>
                            

                           
                 
                
        </>
    );
};

export default Daisy;


