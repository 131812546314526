import React, { createContext, useState, useEffect } from "react";

// create context
const UserContext = createContext([{}, () => {}]);

const UserContextProvider = ( props ) => {
  // the value that will be given to the context
  const [state, setState] = useState({
    username: "",
  });

  /*
  useEffect(() => {
    const fetchUser = () => {
      // this would usually be your own backend, or localStorage
      // for example
      fetch("https://randomuser.me/api/")
        .then((response) => response.json())
        .then((result) => setUser(result.results[0]))
        .catch((error) => console.log("An error occured"));
    };

    fetchUser();
  }, []);*/

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider value={[state, setState]}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };