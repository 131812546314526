import logo from './logo.svg';
import {Routes, Route} from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import Daisy from './pages/Daisy';
import Wallet from './pages/Wallet';
import Page404 from './pages/404Page'

function App() {
  return (
    <div className="App" >
     <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='wallet' element={<Wallet />} />
          <Route path='daisy' element={<Daisy/>}/>
          <Route path='*' element={<Page404/>} />
    </Routes>
    </div>
  );
}

export default App;
